import store from '@/store';
import sharedServices from '@/services/shared-services';
import transactionServices from '@/services/transaction-services';
import moment from 'moment';

import valueWraper from './keyValueMaper';

import arabicDate from './arabicDate';

var statementDateTime;

const buildTokenModel = () =>
{
    let requestInfo = {
        requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
        requestDate: store.get('requestKeys/requestDate'),
        channelKey: store.get('requestKeys/channelKey'),
        identityKey: store.get('requestKeys/identityKey'),
        channelId: store.get('requestKeys/channelId'),
        identitySeed: store.get('requestKeys/identitySeed'),
        userName: store.get('requestKeys/userName'),
    }

    return requestInfo;
}


const buildStatementHistoryRequestModel = async () => // every thing fine
{
    return sharedServices.getToken('entity', store.get('requestKeys/userName')).then(tokenRes =>
    {

        store.set('requestKeys/channelKey', tokenRes.data.results.channelKey);
        store.set('requestKeys/identityKey', tokenRes.data.results.identityKey);
        store.set('requestKeys/requestDate', tokenRes.data.results.requestDate);

        let requestInfo = {
            requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
            channelKey: tokenRes.data.results.channelKey,
            identityKey: tokenRes.data.results.identityKey,
            requestDate: tokenRes.data.results.requestDate,
            channelId: store.get('requestKeys/channelId'),
            identitySeed: store.get('requestKeys/identitySeed'),
            userName: store.get('requestKeys/userName'),
        }

        let date = moment().utc();

        var endDt = moment(date).format('yyyy-MM-DD hh:mm:ss');

        var before120days = date.subtract(120, "days");
        var startDt = moment(before120days).format('yyyy-MM-DD hh:mm:ss'); // 4 months peche

        let card = store.get('card/details');

        let statementHistoryRequestModel = {
            requestInfo: requestInfo,
            accountId: card.accountNo ?? '',
            statSelRangeDt: {
                endDt: endDt, // current date
                startDt: startDt, // 120 days before current date
                keyValues: {
                    data: null,
                    key: null,
                    type: null,
                }
            }

        }

        return statementHistoryRequestModel;
    });
}

const _buildRecentActivityRequestModel = async (DateTime) =>
{
    return sharedServices.getToken('entity', store.get('requestKeys/userName')).then(tokenRes =>
    {
        store.set('requestKeys/channelKey', tokenRes.data.results.channelKey);
        store.set('requestKeys/identityKey', tokenRes.data.results.identityKey);
        store.set('requestKeys/requestDate', tokenRes.data.results.requestDate);

        let requestInfo = {
            requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
            channelKey: tokenRes.data.results.channelKey,
            identityKey: tokenRes.data.results.identityKey,
            requestDate: tokenRes.data.results.requestDate,
            channelId: store.get('requestKeys/channelId'),
            identitySeed: store.get('requestKeys/identitySeed'),
            userName: store.get('requestKeys/userName'),
        }

        let card = store.get('card/details');

        let listKeyValues = [];

        var stmtDate = moment(statementDateTime ?? new Date()).format('yyyyMMDD');

        listKeyValues.push({
            data: stmtDate,
            key: 'STATEMENT_DATE',
            type: null,
        });

        listKeyValues.push({
            data: card?.accountNo ?? '',
            key: 'ACCOUNT_ID',
            type: null,
        });

        var endDt = moment(statementDateTime ?? new Date()).format('yyyy-MM-DD hh:mm:ss');

        let before500days = statementDateTime ? moment(statementDateTime).subtract(500, 'days') : moment().subtract(500, 'days');
        let startDt = moment(before500days).format('yyyy-MM-DD hh:mm:ss');

        let recentActivityRequestModel = {
            requestInfo: requestInfo,
            cardReference: null,
            keyValues: listKeyValues,
            rangeDate: {
                endDt: endDt,
                keyValues: null,
                keyValuesLength: 0,
                startDt: startDt,
            }
        }

        return recentActivityRequestModel;
    });
}

const getCurrency = (code) =>
{
    // let arabic = !Constants.dataManager.isLanguageEn;
    let currency = "";
    switch (code)
    {
        case "682":
            currency = "§";
            // currency = arabic == true ? "ريال سعودي" : "SAR";
            break;
        case "840":
            currency = "USD";
            // currency = arabic == true ? "دولار" : "USD";
            break;
        case "978":
            currency = "EUR";
            // currency = arabic == true ? "EUR" : "EUR";
            break;
        case "124":
            currency = "CAD";
            // currency = arabic == true ? "CAD" : "CAD";
            break;
        case "144":
            currency = "LKR";
            // currency = arabic == true ? "LKR" : "LKR";
            break;
        case "036":
            currency = "AUD";
            // currency = arabic == true ? "AUD" : "AUD";
            break;
        case "784":
            currency = "AED";
            // currency = arabic == true ? "AED" : "AED";
            break;
        case "818":
            currency = "EGP";
            // currency = arabic == true ? "EGP" : "EGP";
            break;
        case "826":
            currency = "GBP";
            // currency = arabic == true ? "جنيه إسترليني" : "GBP";
            break;
        case "414":
            currency = "KWD";
            // currency = arabic == true ? "KWD" : "KWD";
            break;
        case "586":
            currency = "PKR";
            // currency = arabic == true ? "PKR" : "PKR";
            break;
        case "356":
            currency = "INR";
            // currency = arabic == true ? "INR" : "INR";
            break;
        case "949":
            currency = "TRY";
            // currency = arabic == true ? "TRY" : "TRY";
            break;
        default:
            return "§";
        // return arabic == true ? "ريال سعودي" : "SAR";
    }
    return currency;
}

const getStatementHistory = async () => 
{
    var stmtDateTime = null;
    var dateArray = [];
    var statementHistoryRequestModel = await buildStatementHistoryRequestModel();

    let statementHistoryResponse = await transactionServices.getStatementHistory(statementHistoryRequestModel);

    statementHistoryResponse?.data?.keyValues?.forEach((element) =>
    {
        if (element.key == 'NextStatement')
        {
            var date = element.data;
            if (date != null)
            {
                // let date = "31-MAR-27"; get date this format

                let year = `20${date.split('-')[2]}`;
                let day = date.split('-')[0];
                let month = moment(date).month() + 1;
                stmtDateTime = `${year}-${month}-${day}`;
            }
        }
    });
    statementHistoryResponse?.data?.statement?.forEach((elem) =>
    {
        if (elem.statementDt)
        {
            let day = moment(elem.statementDt).format('DD');
            let month = moment(elem.statementDt).format('MMM');
            let year = moment(elem.statementDt).format('yy');

            let dict = {
                dateAr: arabicDate.get(elem.statementDt, 'ar', true),
                dateEn: day + '-' + month + '-' + year,
                dateValue: day + '-' + month + '-' + year
            }
            dateArray.push(dict)
        }
    });
    return {

        stmtDateTime: stmtDateTime,
        dateArray: dateArray
    };
}

const getRecentActivity = async (grouping, dropdownDate, hide) =>
{

    statementDateTime = dropdownDate;

    let recentActivityList = [];

    let recentActivityRequestModel = await _buildRecentActivityRequestModel(statementDateTime);

    let response = await transactionServices.getRecentActivities(recentActivityRequestModel, hide);

    let transactions = [];
    if (response?.data?.transaction)
    {
        transactions = response?.data?.transaction;
    }
    if (transactions.length != 0)
    {
        let list = [];

        transactions.forEach(transaction =>
        {
            var date = transaction.transactionCommon?.transDate;

            var description = transaction.transactionIssuing?.description;
            if (description == null || description == "")
            {
                transaction.transactionCommon?.keyValues?.forEach((element) =>
                {
                    if (element.key == 'TRANSACTION_DESCRIPTION')
                    {
                        description = element.data;
                    }
                });
            }
            var amount = valueWraper.divideByExponents(transaction.transactionIssuing?.billCurAmt?.amt, transaction.transactionIssuing?.billCurAmt?.curCode);
            var billedAmountCur = getCurrency(transaction.transactionIssuing?.billCurAmt?.curCode || '');
            let cr;
            if (transaction.transactionCommon?.transSign == 'C')
            {
                cr = 'CR';
            }

            let foreignAmount;
            let foreignCurrCode;
            if (transaction.transactionCommon?.transAmt?.curCode != transaction.transactionIssuing?.billCurAmt?.curCode)
            {
                if (transaction.transactionCommon?.transSign == 'C')
                {
                    cr = 'CR';
                }
                foreignAmount = valueWraper.divideByExponents(transaction.transactionCommon?.transAmt?.amt, transaction.transactionCommon?.transAmt?.curCode);
                foreignCurrCode = getCurrency(transaction.transactionCommon?.transAmt?.curCode || '');
            }

            var currCode = getCurrency(transaction.transactionIssuing?.billCurAmt?.curCode || '');
            var embossedName = transaction.transactionCommon?.keyValues?.find((element) => element.key == 'Embossed_name').data;

            // 2022-11-16T04:52:07.100Z
            var dateTime = `${date}`;

            var location = transaction.transactionCommon?.country?.countryName;
            var city = transaction.transactionCommon?.keyValues?.find((element) => element.key == "MerchantCity").data;

            var transAmount = valueWraper.divideByExponents(transaction.transactionCommon?.transAmt?.amt, transaction.transactionCommon?.transAmt?.curCode);
            var transAmountCur = getCurrency(transaction.transactionCommon?.transAmt?.curCode || '');
            var transAmt = transaction.transactionCommon?.transAmt?.amt;

            var desc = transaction.transactionIssuing?.description;

            var transId = transaction.transactionCommon?.transId;

            var postedDate = transaction.transactionCommon?.keyValues?.find((element) => element.key == "POSTING_DATE").data;

            var merchant = transaction.transactionCommon?.keyValues?.find((element) => element.key == "MerchantName").data;

            var transCurCode = transaction.transactionCommon?.transAmt?.curCode;

            var serialNo = transaction.transactionCommon?.transId;

            let finalTransaction = {
                dateTime: moment(dateTime).utc().format('DD MMM, yyyy'),
                date: `${moment(dateTime).day()}-${moment(dateTime).month()}-${moment(dateTime).year()}`,
                dateSort: moment(dateTime).utc().format('dddd DD, MMMM'),
                amount: amount,
                foreignAmount: foreignAmount,
                foreignCurrencyCode: foreignCurrCode,
                cr: cr,
                currencyCode: currCode,
                title: description ?? '',
                icon: require(`@/assets/images/icons/${cr != null ? 'transaction-success' : 'merchandise'}.png`),
                // icon: require(`@/assets/images/icons/merchandise.png`),
                subtitle: embossedName,
                location: location,
                billedAmount: amount,
                billedAmountCur: billedAmountCur,

                transAmountCur: transAmountCur,
                transAmt: transAmount,

                description: desc,
                transId: transId,
                postingDate: moment(postedDate).utc().format('DD MMM, yyyy'),
                merchant: merchant,
                transCurCode: transCurCode,
                // cachBackAmt: transAmt,
                serialNo: serialNo,
                city: city,
                transaction: transaction,
                dialogDetail: {
                    'Transaction Date': moment(postedDate).utc().format('DD MMM, yyyy'),
                    'Posting Date': moment(dateTime).utc().format('DD MMM, yyyy'),
                    // Amount: amount,
                    // 'Foreign Amount': foreignAmount,
                    // 'Foreign Currency': foreignCurrCode,
                    // 'Currency': currCode,
                    // 'Cash Back Amount': description ?? '',
                    'Cardmember Name': embossedName,
                    'Merchant Name': merchant,
                    City: city,
                    Country: location,

                    'Billing Amount': {
                        amt: amount,
                        cur: billedAmountCur
                    },
                    // 'Billed Amount Currency': billedAmountCur,

                    // 'Transaction Amount Currency': transAmountCur,
                    'Transaction Amount': {
                        amt: transAmount,
                        cur: transAmountCur
                    },
                    // Description: desc,
                    'Transaction Ref': transId,
                   
                   
                    // 'Transaction Currency Code': transCurCode,
                    // 'Cash Back Amount': transAmt,
                   
                }
            };

            list.push(finalTransaction);
        });

        list.reverse();
        return list;
    } else
    {
        recentActivityList = [];
    }
}

// const getMonth = (val) =>
// {
//     console.log(val);
//     let month = {
//         Jan: 'Jan',
//         Feb: 'Feb',
//         Mar: 'Mar',
//         Apr: 'Apr',
//         May: 'May',
//         Jun: 'Jun',
//         Jul: 'Jul',
//         Aug: 'Aug',
//         Sep: 'Sep',
//         Oct: 'Oct',
//         Nov: 'Nov',
//         Dec: 'Dec',
//     }
//     let monthAr = {
//         Jan: 'يناير',
//         Feb: 'فبراير',
//         Mar: 'مارس',
//         Apr: 'أبريل',
//         May: 'مايو',
//         Jun: 'يونيو',
//         Jul: 'يوليو',
//         Aug: 'أغسطس',
//         Sep: 'سبتمبر',
//         Oct: 'أكتوبر',
//         Nov: 'نوفمبر',
//         Dec: 'ديسمبر',
//     }
//     let lang = localStorage.getItem('language')
//     if (lang == 'en')
//     {
//         let mon = month[val]
//         return mon
//     }
//     else
//     {
//         let mon = monthAr[val]
//         return val ? mon : ''
//     }
// }


export default {
    getRecentActivity,
    getStatementHistory
}